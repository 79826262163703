<template>
  <div class="settings-locations">
    <vue-good-table
      :ref="tableReference"
      mode="remote"
      :is-loading="isLoading"
      :total-rows="totalRecords"
      :columns="columns"
      :rows="locations"
      style-class="vgt-table"
      :max-height="maxHeight"
      :fixed-header="true"
      :pagination-options="{
        enabled: true
      }"
      :search-options="{
        enabled: true,
        externalQuery: settingsSearch
      }"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
      @on-search="onSearchTermChange"
      @on-row-click="openEdit"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span
          v-if="props.column.label == $t('settingsLocationsTable.addrress')"
          class="td-date"
        >
          {{ props.row.address_1 }} {{ props.row.address_2 }}
        </span>
        <span
          v-else-if="props.column.label == $t('settingsLocationsTable.zip')"
          class="td-date"
        >
          <span v-if="props.row.zip !== '0'">{{ props.row.zip }}</span>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <div
        v-show="isLoading"
        slot="loadingContent"
      >
        <LoadingSpinner />
      </div>
      <div
        v-show="!isLoading"
        slot="emptystate"
      >
        <span>{{ $t('noLocationsFound') }}</span>
      </div>
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <PaginationFooter
          v-show="paginationEnabled"
          :total="props.total"
          :server-params="serverParams"
          :per-page-changed="props.perPageChanged"
          :page-changed="onPageChange"
        />
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import { mapGetters } from 'vuex'
import { SettingsLocations } from '@/mixins/TableColumnsHelper'
import { SettingsCrud } from '@/mixins/SettingsCrud'
import { GET_LOCATIONS_FOR_DATA_TABLE } from '@/store/modules/locations/constants'
import LoadingSpinner from '@/components/common/LoadingSpinner'
import { RemoteTable } from '@/mixins/remoteTables/RemoteTable'
import PaginationFooter from '@/components/common/PaginationFooter'

export default {
  name: 'SettingsLocations',
  components: {
    VueGoodTable,
    PaginationFooter,
    LoadingSpinner
  },
  mixins: [SettingsLocations, SettingsCrud, RemoteTable],
  data () {
    return {
      tableReference: 'SettingsLocations',
      tableMaxHeight: 154,
      defaultSortOrder: 'asc',
      defaultSortField: 'name',
      action: GET_LOCATIONS_FOR_DATA_TABLE
    }
  },
  computed: {
    ...mapGetters({
      locations: 'getLocations'
    })
  },
  created () {
    this.loadItems()
  }
}
</script>
