import { EDITING_SETTING, SET_SEARCHING } from '@/store/modules/locations/constants'
import { Helpers } from '@/mixins/Helpers'
import { mapMutations, mapGetters } from 'vuex'

export const SettingsCrud = {
  mixins: [Helpers],
  computed: {
    ...mapGetters({
      settingsSearch: 'getSettingsSearch'
    })
  },
  methods: {
    ...mapMutations({
      setSettingToEdit: EDITING_SETTING,
      onSearch: SET_SEARCHING
    }),
    getData (action) {
      return this.$genericDispatch(action)
    },
    openEdit (data) {
      this.setSettingToEdit(data.row)
    }
  }
}
